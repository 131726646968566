// CUSTOM OPTIONS //
.cg {
    width: 100%;
    max-width: 117.8rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin: 0 auto;
}
// END //
// PROJECT COLORS //
$black-1: #474747;
$orange-1: #F37321;
$orange-2: #EE9B66;
$line-color: rgba(196, 196, 196, 0.3);

$bg-silver: #F6F6F6;
$bg-silver2:  #E5E5E5;

$txt-black: #000;
$txt-white: #fff;
$txt-white2: #F6F6F6;
$txt-silver: #B7B7B7;
$txt-silver2: #737373;
// END //
// CUSTOM ELEMENTS //
.ttl {
    font-weight: 600;
    font-size: 3rem;
    line-height: 3.7rem;
    color: $orange-1;
    margin: 0;
}
.ttl-2 {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 3rem;
    color: $txt-black;
}
.txt {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: $txt-white;
    text-decoration: none;
    margin: 0;
}
.btn {
    background: $orange-2;
    border: none;
    border-radius: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4.5rem;

    text-decoration: none;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: $txt-white2;
    transition: all .3s;

    text-transform: uppercase;

    &:hover,
    &:active {
        box-shadow: 0px 5px 15px rgba(243, 115, 33, 0.36);
    }
}

.inp {
    width: 100%;
    max-width: 36.2rem;

    border: none;
    border-bottom: 1px solid $txt-silver;
    background: none;
    padding-bottom: .4rem;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 2.1rem;
    color: $txt-black;
    transition: all .3s;

    &::placeholder {
        color: $txt-silver;
        transition: all .3s;
    }

    &:active,
    &:focus {
        border-bottom: 1px solid $orange-1;

        &::placeholder {
            color: $txt-black;
        }
    }

}
// END //

// CRITICAL STYLES OF FIRST BLOCK //

// HEADER MENU STYLES //
.body {
    position: relative;

    .vertical-line {
        position: absolute;
        top: 0;
        height: 100%;
        width: .1rem;
        background-color: $line-color;
        z-index: 1;

        &.line-1 {
            left: 7.8%;
        }

        &.line-2 {
            left: 19.2%;
        }

        &.line-3 {
            left: 34.65%;
        }

        &.line-4 {
            left: 82.34%;
        }

        &.line-5 {
            left: 7.8%;
        }

        &.line-6 {
            left: 19.2%;
        }

        &.line-7 {
            left: 82.35%;
        }
    }
}
.header-menu {
    position: relative;
    z-index: 3;
    padding-top: 2.7rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .camp-page__back {
        position: absolute;
        top: 100%;
        left: 2%;
        border-left: 1px solid $black-1;
        border-bottom: 1px solid $black-1;
        width: 2.2rem;
        height: 2.2rem;
        transform: rotate(45deg);
    }

    &__wrap {
        width: 12.7rem;
        height: 12.3rem;
        position: absolute;
        top: 5%;
        left: 36%;

        &.camp-page__logo {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__logo {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
        display: block;
    }

    &__socials {
        display: none;
        align-items: center;
        justify-content: flex-start;

        .header-socials__link {
            width: 2.5rem;
            height: 2.5rem;

            &:not(:last-of-type) {
                margin-right: 1.5rem;
            }
        }

        .header-socials__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
            display: block;
        }
    }

    &__langs {
        margin-right: .6rem;

        .lang {
            color: $txt-silver;
            line-height: 1.7rem;
        }
        
        .lang-slach,
        .lang-active {
            color: $txt-black;
        }
    }

}
// END //

// FIRST SECTION STYLES //
.main .first-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: -4.6rem;
    position: relative;
    z-index: 2;

    &__component {
        position: absolute;
        z-index: 2;

        &.component-elipse {
            left: 96%;
            top: 31.7%;
        }

        &.component-plus {
            left: -6%;
            top: 18%;
        }

        &.component-shape {
            left: 30%;
            top: 79.4%;
        }

        &.component-wawe {
            left: 77.7%;
            top: 91.7%;
        }
    }

    &__description {
        width: 100%;
        max-width: 36.2rem;
        margin-right: 12.9rem;

        .description-ttl {
            width: 100%;
            max-width: 35.9rem;
            margin-bottom: 2.2rem;
        }

        .description-txt {
            color: $txt-silver2;
            margin-bottom: 6rem;
        }

        .description-socials {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &__link {
                width: 3rem;
                height: 3rem;

                &:not(:last-of-type) {
                    margin-right: 3rem;
                }
            }

            &__img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: 50% 50%;
                display: block;
            }
        }
        
    }

    &__slider {
        width: 46rem;
        
        div {
            width: 46rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: 50% 50%;
                display: block;
            }
            
        }

        .slick-prev {
            top: unset;
            left: unset;
            right: -42.5%;
            bottom: 0;
            background: url('../img/components/btn-arrow.png') 50% 50% no-repeat;
            transform: rotate(180deg);
        }

        .slick-next {
            top: unset;
            right: -42.5%;
            bottom: 4.21%;
            background: url('../img/components/btn-arrow.png') 50% 50% no-repeat;
            
        }

        .slick-prev,
        .slick-next {
            width: 6.8rem;
            height: 6rem;
            background-color: $black-1;
            transition: all .3s;

            &:hover {
                background-color: $orange-1;
            }
        }
    }

}
// END //

// CAMP PAGE FIRST BLOCK //
.camp-main {
    position: relative;
    z-index: 2;
    padding-top: 14.1rem;
    padding-bottom: 5.6rem;

    &__component {
        position: absolute;

        &.main-shape {
            width: 5.8rem;
            top: 2%;
            left: -10%;
        }
        &.main-rounds {
            top: 15%;
            left: 96%;
        }
        &.main-dots {
            z-index: -1;
            top: 21%;
            left: 46%;
        }
        &.main-wawe1 {
            width: 6rem;
            top: 53%;
            left: 86%;
        }
        &.main-plus {
            width: 4rem;
            top: 65%;
            left: 56.47%;
        }
        &.main-wawe2 {
            width: 5.6rem;
            top: 94%;
            left: 66%;
            z-index: 3;
            opacity: .5;
        }
    }
}

.camp-main__booking {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 13rem;

    .booking-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 12.7rem;

        &__ttl {
            max-width: 41.8rem;
            margin-bottom: 3rem;

            &.camp2-ttl {
                max-width: 43.8rem;
            }
        }

        &__wrap {
            max-width: 46rem;
            min-height: 33.4rem;
        }

        &__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
            display: block;
        }
    }

    .booking-block {

        .camps-card {
            width: 46.1rem;
            max-width: unset;
            background-color: $txt-white;

            &.camp2-card {
                width: unset;
                max-width: 36.1rem;
            }

            &:first-of-type {
                margin-right: 0;
            }

            &:hover {
                background-color: $orange-2;
                border: 1px solid $orange-2;
                box-shadow: 0 1rem 2.5rem rgba(243, 115, 33, 0.5);

                

            }
            .camps-card__list {
                margin-bottom: 2.2rem;

                .list-item:first-of-type {
                    min-height: unset;
                }

                .list-item:not(:last-of-type) {
                    margin-bottom: 2rem;
                }
            }
            
            .price-block {
                width: 100%;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                .camps-card__price {
                    font-weight: 600;
                    font-size: 1.8rem;
                    line-height: 2.3rem;
                    max-width: 17rem;
                }
            }

            .camps-card__more:link,
            .camps-card__more:visited {
                align-self: center;
            }
        }
    }
}

.camps-card__description {
    font-weight: 300;
    font-size: 1.1rem;
    line-height: 1.2rem;
    color: $txt-silver2;
    margin-bottom: 4rem;
}

.camp-main__program {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .program-left {

        &__subttl {
            font-weight: 500;
            font-size: 1.8rem;
            line-height: 2.3rem;
            color: $orange-1;
            margin-top: 1.6rem;
        }

        &__description {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;

            .description-item {
                position: relative;
                color: $black-1;
                margin-top: 3.1rem;
                margin-right: 7.6rem;
                max-width: 14rem;

                &:first-of-type,
                &:nth-of-type(2),
                &:nth-of-type(3) {
                    width: 50%;
                    max-width: 27rem;
                }

                &::after {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: -.7rem;
                    left: -1rem;
                    width: 2rem;
                    height: 2rem;
                    border-radius: 50%;
                    background-color: $orange-1;
                }
            }

            .description-item__camp2 {
                position: relative;
                color: $black-1;
                margin-top: 3.1rem;
                margin-right: 7.6rem;
                width: 100%;
                max-width: 28rem;

                &::after {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: -.7rem;
                    left: -1rem;
                    width: 2rem;
                    height: 2rem;
                    border-radius: 50%;
                    background-color: $orange-1;
                }
            }
        }
    }

    .program-right {
        margin-top: 5.2rem;

        .camp-main__slider {
            width: 100%;
            max-width: 36.2rem;
            min-height: 27rem;
            border-radius: .5rem;
        }
        .camp-main__slider .slide-wrap {
            width: 100%;
            max-width: 36.2rem;
            min-height: 27rem;
            border-radius: .5rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: 50% 50%;
                display: block;
                border-radius: .5rem;
            }
        }
        .camp-page__slider.camp-main__slider {

            .slick-prev,
            .slick-next {
                top: 90%;
            }
            .slick-prev {
                right: 9.25%;
            }
        }
    }
}

// 1439px //
@media only screen and (max-width: 89.9375em) {
    .main .first-section__component.component-plus {
        left: 0%;
    }
    .main .first-section__component.component-elipse {
        left: 89%;
    }
    .camp-main__component.main-shape {
        left: 0%;
    }
    .camp-main__component.main-rounds {
        top: 7%;
        left: 89%;
    }
}

// 1199px //
@media only screen and (max-width: 74.9375em) {
    .main .first-section__component.component-wawe {
        left: 41.5%;
    }
    .main .first-section__description {
        margin-right: 8rem;
    }
    .main .first-section__slider .slick-next,
    .main .first-section__slider .slick-prev {
        right: -14.8%;
    }
}

// 1023px //
@media only screen and (max-width: 63.9375em) {
    .main .first-section__description {
        margin-right: 2rem;
    }
    .camp-main__component.main-wawe2 {
        top: 89%;
        left: 60%;
    }
}

// 1199px //
@media only screen and (max-width: 74.9375em) {
    .body .vertical-line {
        display: none;
    }

    // FIRST SECTION //
    .header-menu {
        justify-content: flex-start;
        padding-top: 2rem;
    }
    .header-menu__wrap {
        width: 6rem;
        height: 6rem;
        position: relative;
        top: unset;
        left: unset;
        margin-right: 1.5rem;
    }
    .header-menu__socials {
        display: flex;
    }
    .header-menu__langs {
        margin-right: 0;
        margin-left: auto;
        display: flex;
        flex-direction: column;
    }
    .header-menu__langs .lang-slach {
        display: none;
    }

    .main .first-section__component.component-plus,
    .main .first-section__component.component-elipse,
    .main .first-section__component.component-wawe {
        display: none;
    }
    .main .first-section__component.component-shape {
        left: 60%;
        top: 108%;
    }
    .main .first-section {
        margin-top: 3.2rem;
        margin-bottom: 3rem;
    }
    .main .first-section__description {
        margin-right: 0;
        max-width: 100%;
    }
    .main .first-section__description .description-txt {
        margin-bottom: 0;
    }
    .main .first-section__description .description-socials {
        display: none;
    }

    .first-section__slider {
        display: none;
    }
    // END //
    .camp-main__booking .booking-info {
        margin-right: 6rem;
    }
    .camp-main__program .program-left__description .description-item {
        margin-right: 2rem;
    }
}

// 991px //
@media only screen and (max-width: 61.9375em) {
    .camp-main__component.main-shape,
    .camp-main__component.main-wawe2,
    .camp-main__component.main-rounds,
    .camp-main__component.main-dots {
        display: none;
    }
    .camp-main__component.main-wawe1 {
        top: 59%;
        left: 58%;
    }
    .camp-main__component.main-plus {
        top: 70%;
        left: 74%;
    }

    .header-menu .camp-page__back {
        top: unset;
        left: 5%;
    }

    .camp-main {
        padding-top: 3rem;
        padding-bottom: 6rem;
    }
    .camp-main__booking {
        margin-bottom: 6rem;
        flex-direction: column;
    }
    .camp-main__booking .booking-info {
        margin-right: 0;
        margin-bottom: 2rem;
    }
    .camp-main__program {
        flex-direction: column;
    }
    .camp-main__program .program-right {
        margin-top: 3.3rem;
        align-self: center;
    }
    .camp-main__program .program-left__description .description-item:first-of-type,
    .camp-main__program .program-left__description .description-item:nth-of-type(2),
    .camp-main__program .program-left__description .description-item:nth-of-type(3),
    .camp-main__program .program-left__description .description-item {
        width: unset;
        max-width: 33%;
    }
}
// 767px //
@media only screen and (max-width: 47.9375em) {
    .camp-main__component.main-wawe1 {
        top: 55%;
        left: 53%;
    }
    .camp-main__component.main-plus {
        top: 67%;
        left: 76%;
    }
    .camp-main__program .program-left__description .description-item:first-of-type,
    .camp-main__program .program-left__description .description-item:nth-of-type(2),
    .camp-main__program .program-left__description .description-item:nth-of-type(3),
    .camp-main__program .program-left__description .description-item {
        max-width: calc(50% - 2rem);
    }
    .camp-main__program .program-left__description .description-item:nth-of-type(2n) {
        margin-right: 0;
    }
}
// 575px //
@media only screen and (max-width: 35.9375em) {
    .main .first-section__description .description-ttl {
        font-size: 2.4rem;
        line-height: 2.9rem;
    }
    .camp-main__booking .booking-info__ttl {
        font-size: 2.5rem;
        line-height: 2.9rem;
    }
    .camp-main__program .program-left__ttl {
        font-size: 1.8rem;
        line-height: 2.3rem;
    }
    .camp-main__program .program-left__subttl {
        font-size: 1.4rem;
        line-height: 1.8rem;
    }
    .camp-main__component.main-wawe1 {
        top: 55.7%;
    }
    .camp-main__booking {
        margin-bottom: 1.6rem;
    }
    .camp-main__booking .booking-info__wrap {
        min-height: unset;
    }
    .camp-main__booking .booking-block .camps-card {
        width: 100%;
        max-width: 46.1rem;
    }
}
// 424px //
@media only screen and (max-width: 26.5em) {
    .camp-main__component.main-wawe1 {
        top: 54.5%;
    }
    .camp-main__booking .booking-info__ttl {
        max-width: 28rem;
    }
    .camp-main__booking .booking-block .camps-card .price-block {
        flex-wrap: wrap;
    }
    .camp-main__booking .booking-block .camps-card .price-block .camps-card__price:first-of-type {
        margin-right: 2rem;
    }
    .camp-main__program .program-left__description {
        flex-direction: column;
    }
    .camp-main__program .program-left__description .description-item:first-of-type,
    .camp-main__program .program-left__description .description-item:nth-of-type(2),
    .camp-main__program .program-left__description .description-item:nth-of-type(3),
    .camp-main__program .program-left__description .description-item {
        max-width: 100%;
        margin-right: 0;
    }
    .camp-main__program .program-right .camp-main__slider {
        max-width: 28rem;
        min-height: unset;
    }
    .camp-main__program .program-right .camp-main__slider .slide-wrap {
        min-height: unset;
    }
}

// END //





// SECTION CAMPS STYLES //
.camps-wrap {
    position: relative;
    padding-top: 9rem;
    padding-bottom: 7.7rem;
    background-color: rgba(229, 229, 229, 0.35);

    .camps-component {
        position: absolute;
        z-index: 2;

        &.camps-plus {
            left: 85%;
            top: 20%;
        }

        &.camps-shape {
            left: 10.1%;
            top: 30%;
        }

        &.camps-wawe {
            left: 32.5%;
            top: 70%;
        }
    }
}
.camps {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    z-index: 2;

    &-ttl {
        width: 100%;
        max-width: 33rem;
        margin-right: 3rem;
        margin-top: 6.2rem;
    }
}

.camps-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: $bg-silver;
    border: 1px solid $txt-silver;
    border-radius: 1rem;
    padding: 1.7rem 3rem 3rem 3rem;
    width: 100%;
    max-width: 36.1rem;
    min-height: 42.3rem;
    transition: all .6s;

    &:hover {
        background-color: $orange-2;
        border: 1px solid $orange-2;
        box-shadow: 0 1rem 2.5rem rgba(243, 115, 33, 0.5);

        & > .camps-card__name,
        & > .camps-card__price,
        & > .price-block > .camps-card__price,
        & > .camps-card__description {
            color: $txt-white;
        }

        & > .camps-card__list .list-item {
            color: $txt-white;

            &:first-of-type,
            &:nth-of-type(2),
            &:nth-of-type(3),
            &:nth-of-type(4) {
                
                &::before {
                    filter: invert(1);
                }
            }
        }

        & > .camps-card__more {
            border: 2px solid $txt-white;
            color: $txt-white;
        }

    }

    &__name {
        width: 100%;
        max-width: 26rem;
        display: block;
        margin-bottom: 1.9rem;
        font-weight: 600;
        color: $txt-black;
    }

    &__list {
        margin-bottom: 2rem;
        list-style-type: none;

        .list-item {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            font-weight: 300;
            font-size: 1.4rem;
            line-height: 1.8rem;
            color: $black-1;
            position: relative;
            padding-left: 4.7rem;
            
            &:first-of-type {
                min-height: 9rem;

                &::before {
                    content: url('../img/components/icon-calendar.png');
                    position: absolute;
                    left: 0;
                }
            }

            &:nth-of-type(2) {
                max-width: 28rem;

                &::before {
                    content: url('../img/components/icon-location.png');
                    position: absolute;
                    left: 0;
                }
            }

            &:nth-of-type(3) {
                max-width: 20rem;
                
                &::before {
                    content: url('../img/components/icon-children.png');
                    position: absolute;
                    left: 0;
                }
            }

            &:nth-of-type(4) {
                max-width: 22rem;
                
                &::before {
                    content: url('../img/components/icon-bus.png');
                    position: absolute;
                    left: 0;
                }
            }

            &:not(:last-of-type) {
                margin-bottom: .9rem;
            }
        }

    }

    &__price {
        font-weight: 600;
        font-size: 2.4rem;
        line-height: 3rem;
        color: $txt-black;
        margin-bottom: 2rem;
    }

    &__more:link,
    &__more:visited {
        text-decoration: none;
        width: 100%;
        max-width: 22rem;
        height: 4.5rem;
        background: $orange-2;
        border-radius: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.8rem;
        color: $txt-white2;
    }
}
// END //

// REVIEW SECTION STYLES //
.review {
    position: relative;
    padding-top: 5.3rem;
    padding-bottom: 5.9rem;

    .review-component {
        position: absolute;

        &.review-dots {
            top: 16%;
            left: -23.2%;
        }

        &.review-wawe {
            top: 9%;
            left: 73%;
        }

        &.review-plus {
            top: 88%;
            left: -11%;
        }

        &.review-round {
            top: 62%;
            left: 39%;
        }

        &.review-wawe2 {
            top: 86%;
            left: 87%;
        }
    }

    &-ttl {
        margin-bottom: 4.9rem;
        width: 100%;
        max-width: 51.8rem;
    }
    
    .video_wrapper {
        position: relative;
        width: 100%;
        max-width: 95rem;
        height: 40rem;
        margin: 0 auto 8.3rem auto;
        z-index: 2;
    }
      
      
      .videoIframe {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
      }
      
      .videoPoster {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background-image:linear-gradient(rgba(71, 71, 71, 0.5), rgba(71, 71, 71, 0.5)), url('../img/bg/review-video__img.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            border: none;
            text-indent: -999em;
            overflow: hidden;
            opacity: 1;
            -webkit-transition: opacity 800ms, height 0s;
            -moz-transition: opacity 800ms, height 0s;
            transition: opacity 800ms, height 0s;
            -webkit-transition-delay: 0s, 0s;
            -moz-transition-delay: 0s, 0s;
            transition-delay: 0s, 0s;
      }
      .videoPoster:hover {
        cursor: pointer;
      }
      .videoPoster:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80px;
        height: 80px;
        margin: -40px 0 0 -40px;
        border: 5px solid #fff;
        background-color: #f00;
        border-radius: 100%;
        -webkit-transition: border-color 300ms;
        -moz-transition: border-color 300ms;
        transition: border-color 300ms;
      }
      .videoPoster:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50.6%;
        transform: translate(-50%, -50%);
        border-left: 30px solid #fff;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
          -webkit-transition: border-color 300ms;
          -moz-transition: border-color 300ms;
          transition: border-color 300ms;
      }
      .videoPoster:hover:before,  .videoPoster:focus:before {
          border-color: $orange-2;
          background-color: #fff;
      }
      .videoPoster:hover:after, .videoPoster:focus:after {
        border-left-color: $orange-2;
      }
      .videoWrapperActive .videoPoster {
        opacity: 0;
        height: 0;
        -webkit-transition-delay: 0s, 800ms;
        -moz-transition-delay: 0s, 800ms;
        transition-delay: 0s, 800ms;
      }





    &-slider__ttl {
        width: 100%;
        max-width: 37rem;
        margin-bottom: 2rem;
    }

    &-slider {
        width: 100%;
        max-width: 72.4rem;
        margin: 0 auto;
        position: relative;
        z-index: 2;

        .slick-prev {
            background: url('../img/components/review-slider__arrow.png') 50% 50% no-repeat;
            transform: translate(0, -50%) rotate(180deg);
            left: -5.7rem;
        }

        .slick-next {
            background: url('../img/components/review-slider__arrow.png') 50% 50% no-repeat;
            right: -5.7rem;
            transform: translate(0, -50%);
        }
    }
}
// END //

// STATISTIC SECTION STYLES //
.statistic-wrap {
    position: relative;
    padding-top: 6.4rem;
    padding-bottom: 5.5rem;
    background-color: $bg-silver;

    .statistic-component {
        position: absolute;

        &.statistic-dots {
            left: 64.4%;
            top: 63%;
        }
    }
}
.statistic {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.statistic-info {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: 38.6rem;
    margin-right: 7.7rem;

    &__ttl {
        color: $orange-1;
        width: 100%;
        margin-bottom: 3rem;
    }

    &__block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 50%;
        max-width: 15rem;

        &:nth-of-type(1),
        &:nth-of-type(2) {
            margin-bottom: 5rem;
        }

        &:nth-of-type(2n+1) {
            margin-right: 2rem;
        }

        &:last-of-type .block-ttl {
            max-width: 14.9rem;
        }

        .block-img__wrap {
            width: 3rem;
            height: 3rem;
            margin-bottom: .8rem;

            .block-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: 50% 50%;
                display: block;
            }
        }

        .block-ttl {
            font-weight: 600;
            font-size: 1.4rem;
            line-height: 1;
            color: $black-1;
            margin-bottom: .5rem;
            width: 100%;
            max-width: 11.9rem;
            min-height: 2.8rem;
            
        }

        .block-count {
            font-weight: bold;
            font-size: 3.6rem;
            line-height: 4.5rem;
            color: $orange-2;
            transition: all .3s;
            opacity: 0;
        }
    }
}
.statistic-years {
    position: relative;

    &__wrap {
        width: 100%;
        max-width: 61.8rem;
        min-height: 39.2rem;
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
        display: block;
    }

    &__btn {
        position: absolute;
        top: 20%;
        right: -12%;
        min-width: 16.6rem;
    }
}
// END //

// FORM-DISCOUNT SECTION STYLES //
.form-discount {
    position: relative;
    padding-top: 5.2rem;
    padding-bottom: 8rem;


    .discount-rectangular {
        position: absolute;
        z-index: 2;
        top: 21.1%;
        left: 22%;
        width: 13.7rem;
        height: 52.7rem;
        background: $txt-white2;
        border-radius: .5rem;
    }

    .discount-component {
        position: absolute;

        &.discount-wawe2 {
            top: 24%;
            left: 80%;
        }

        &.discount-shape {
            top: 44%;
            left: 84%;
        }

        &.discount-rounds {
            left: 27.5%;
            top: 7%;
        }

        &.discount-plus {
            top: 30%;
            left: 7%;
        }

        &.discount-wawe {
            z-index: 4;
            top: 55.2%;
            left: 23%;
        }
    }


    &__ttl {
        text-align: center;
        width: 100%;
        max-width: 43rem;
        margin: 0 auto 1.6rem auto;

    }

    &__subttl {
        text-align: center;
        color: $orange-1;
        line-height: 3.6rem;
        width: 100%;
        max-width: 78rem;
        margin: 0 auto 3.2rem auto;
    }

    &__gallery {

        .gallery-ttl {
            color: $orange-1;
            width: 100%;
            margin-bottom: 3rem;
        }

        .discount-gallery {
            position: relative;
            z-index: 2;

            .discount-gallery__wrap {
                position: relative;
                border-radius: .5rem;

                .discount-gallery__slide {

                    .discount-gallery__img {
                        border-radius: .5rem;
                    }

                    .img-overlay {
                        width: 100%;
                        max-width: 26.4rem;
                        height: 100%;
                        opacity: 0;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        text-align: center;
                        transition: all .3s;
                        cursor: pointer;
                        background-color: rgba(0, 0, 0, 0.5);
                        border-radius: .5rem;

                        &::after {
                            content: url('../img/components/gallery-search.png');
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            transition: all .3s;
                            opacity: 0;
                            cursor: pointer;
                        }
                    }
                }

                &:hover .img-overlay,
                &:hover .img-overlay::after {
                    opacity: 1;
                }
            }
            

            .slick-prev {
                background: url('../img/components/review-slider__arrow.png') 50% 50% no-repeat;
                background-size: 1.2rem;
                background-color: $txt-white;
                left: 1.2rem;
                width: 3rem;
                height: 3rem;
                border-radius: 50%;
                z-index: 4;
                transform: translate(0, -50%) rotate(180deg);
            }

            .slick-next {
                background: url('../img/components/review-slider__arrow.png') 50% 50% no-repeat;
                background-size: 1.2rem;
                background-color: $txt-white;
                right: 3.2rem;
                width: 3rem;
                height: 3rem;
                border-radius: 50%;
                z-index: 4;
                transform: translate(0, -50%);
            }
        }
    }
}
// END //

// FORM BOOKING SECTION STYLES //
.form-booking__wrap {
    position: relative;
    padding-top: 6rem;
    padding-bottom: 7.5rem;
    background-color: $txt-white2;

    .booking-component {
        position: absolute;

        &.booking-dots {
            top: -4.8%;
            left: 14.9%;
        }

        &.booking-plus {
            top: 9%;
            left: 67%;
        }

        &.booking-shape {
            top: 45%;
            left: 12%;
        }

        &.booking-rounds {
            top: 59%;
            left: 74%;
        }

        &.booking-wawe {
            top: 97.2%;
            left: 38.7%;
            z-index: 4;
        }
    }

}
.form-booking .form-booking__form {
    margin: 0 auto;
}
// END //

// CAMP INFO STYLES //
.camp-info__wrap {
    position: relative;
    padding-top: 6rem;
    padding-bottom: 6rem;
    background-color: $txt-white2;

    .camp-info__component {
        position: absolute;

        &.info-rounds {
            top: 38%;
            left: 14%;
            width: 5.4rem;
        }
        &.info-dots {
            top: 68%;
            left: 34.2%;
            width: 32.2rem;
            height: 12.1rem;
        }
        &.info-wawe {
            top: 69%;
            left: 69.5%;
            width: 7.7rem;
        }
    }
}

.camp-info {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.camp-info__slider {
    width: 100%;
    max-width: 49rem;
    min-height: 34rem;
    margin-right: 1.6rem;
    border-radius: .5rem;
}
.camp-info__slider .slide-wrap {
    width: 100%;
    max-width: 49rem;
    min-height: 34rem;
    border-radius: .5rem;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
        display: block;
        border-radius: .5rem;
    }
}

.camp-info__description {
    max-width: 59rem;

    .description-txt {
        margin-top: 4.5rem;
        font-size: 2.4rem;
        line-height: 3rem;
        color: $black-1;

        .txt-bold {
            color: $orange-1;
        }
    }
}

.camp-page__slider {

    .slick-prev,
    .slick-next {
        background-color: $black-1;
        width: 2.25rem;
        height: 2rem;
        z-index: 3;
        top: 93%;
    }

    .slick-prev {
        border-top-left-radius: 10rem;
        border-bottom-left-radius: 10rem;
        left: unset;
        right: 7.4%;

        &:after {
            content: "";
            background-image: url('../img/components/btn-arrow.png');
            background-size: cover;
            width: .3rem;
            height: .7rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(180deg);
        }
    }

    .slick-next {
        border-top-right-radius: 10rem;
        border-bottom-right-radius: 10rem;
        right: 3%;

        &:after {
            content: "";
            background-image: url('../img/components/btn-arrow.png');
            background-size: cover;
            width: .3rem;
            height: .7rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
// END //

// CAMP PLACE STYLES //
.camp-place {
    position: relative;
    z-index: 2;
    padding-top: 9.5rem;
    padding-bottom: 9rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .camp-place__component {
        position: absolute;

        &.place-shape {
            width: 5.8rem;
            top: 73%;
            left: 35%;
        }
        &.place-wawe {
            top: 37%;
            left: 110%;
        }
    }
}

.camp-place__description {
    max-width: 55.7rem;
    margin-right: 1.6rem;

    .description-ttl {
        color: $orange-1;
        margin-bottom: 1.6rem;
    }

    .description-txt {
        color: $txt-black;
        font-weight: normal;
        line-height: 2.1rem;
        margin-bottom: 6rem;
    }

    .description-btn {
        max-width: 26.4rem;
    }
}
.camp-place__map {
    width: 55.8rem;
    height: 34rem;
    border-radius: .5rem;
    box-shadow: 0px 10px 35px rgba(118, 114, 114, 0.5);

    iframe {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
        display: block;
        border-radius: .5rem;
    }
}
// END //

// CAMP FOOD STYLES //
.camp-food__wrap {
    position: relative;
    padding-top: 5.8rem;
    padding-bottom: 5.5rem;
    background-color: $txt-white2;

    .camp-food__component {
        position: absolute;

        &.food-shape {
            width: 3.6rem;
            top: 16%;
            left: 72%;
        }
        &.food-plus {
            top: 42%;
            left: 5%;
        }
        &.food-dots {
            width: 32.3rem;
            height: 12.1rem;
            top: 86%;
            left: 70%;
        }
    }
}

.camp-food {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
}
.camp-food__imgs {
    margin-right: 1.6rem;
    min-width: 42rem;

    &.camp2-food {
        max-width: 55.8rem;
        min-height: 38.1rem;
        border-radius: .5rem;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
            display: block;
            border-radius: .5rem;
        }
    }

    .imgs-top__block {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 3rem;

        .top-img__wrap {
            width: 100%;
            max-width: 26.4rem;
            height: 15.1rem;
            border-radius: .5rem;

            &:first-of-type {
                margin-right: 1.6rem;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: 50% 50%;
                display: block;
                border-radius: .5rem;
            }
        }
    }

    .imgs-bottom__block {
        width: 100%;
        max-width: 55.8rem;
        height: 23.6rem;
        border-radius: .5rem;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
            display: block;
            border-radius: .5rem;
        }
    }
}

.camp-food__description {
    width: 100%;
    max-width: 55.8rem;
    
    .description-ttl {
        color: $orange-1;
        margin-bottom: 1.6rem;
    }

    .description-txt {
        color: $txt-black;
        font-weight: normal;
        line-height: 2.1rem;
        margin-bottom: 6rem;
    }

    .description-btn {
        max-width: 26.4rem;
    }
}
// END //

// CAMP SLIDERS STYLES //
.camp-sliders {
    position: relative;
    padding-top: 7.8rem;
    padding-bottom: 6.4rem;

    .camp-sliders__component {
        position: absolute;

        &.sliders-rounds {
            top: 6%;
            left: 38%;
        }
        &.sliders-wawe {
            top: 34%;
            left: 86%;
        }
        &.sliders-plus {
            top: 38%;
            left: -11%;
        }
    }

    .camp-review__slider {
        margin-bottom: 9.2rem;
    }
}
// END //

// CAMP BOOKING STYLES //
.camp-booking__wrap {
    position: relative;
    padding-top: 5.3rem;
    padding-bottom: 8rem;
    background-color: $txt-white2;

    .camp-booking__component {
        position: absolute;

        &.rounds-1 {
            top: 10%;
            left: 23%;
            width: 3.6rem;
        }
        &.plus {
            top: 38%;
            left: 11%;
        }
        &.wawe-1 {
            top: 84%;
            left: 32.5%;
            z-index: 4;
        }
        &.wawe-2 {
            top: 37%;
            left: 67%;
        }
        &.rounds-2 {
            top: 74%;
            left: 75%;
        }
        &.shape {
            display: none;
        }
    }
}
.camp-booking {

    .camp-booking__form {
        margin:0 auto;
    }

    .camp-booking__ttl {
        max-width: 74rem;
    }

}

// END //

// FOOTER STYLES //
.footer {
    background-color: $black-1;

    &-menu {
        padding-top: 5.1rem;
        padding-bottom: 5rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &__wrap {
            width: 9.1rem;
            height: 8.9rem;
            margin-left: 9.9rem;
            margin-right: 10.4rem;
            text-decoration: none;
        }

        &__logo {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
            display: block;
        }

        &__social {
            margin-right: 11.5rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            .social-description {
                margin-bottom: 2.6rem;
                width: 100%;
                max-width: 18rem;
            }

            .social-block {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                &__link {
                    width: 3rem;
                    height: 3rem;
                    text-decoration: none;

                    &:not(:last-of-type) {
                        margin-right: 3rem;
                    }

                    .social-link__icon {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: 50% 50%;
                        display: block;
                    }
                }
            }
        }

        &__contacts {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .contact-phone {
                margin-bottom: .8rem;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                &__link:link,
                &__link:visited {
                    color: $txt-white;
                    font-weight: 300;
                    position: relative;
                    text-decoration: none;
                    margin-top: 1.7rem;
                    padding-left: 2rem;

                    &::after {
                        content: "";
                        width: 1.4rem;
                        height: 1.4rem;
                        background-image: url("../img/components/phone-icon.png");
                        background-repeat: no-repeat;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                    }
                }
            }

            .contact-mail {
                font-weight: 300;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                &__link:link,
                &__link:visited {
                    text-decoration: none;
                    color: $orange-1;
                }
            }
        }
    }
}
// END //


// STYLES OF FORM //
.form-discount__form {
    position: relative;
    z-index: 3;
    width: 100%;
    max-width: 55.7rem;
    min-height: 55.5rem;
    padding: 4.3rem 9.6rem 5.2rem 9.6rem;
    margin: 0 auto 9.2rem auto;

    background: $txt-white;
    border: 1px solid $orange-1;
    border-radius: 1rem;
    box-shadow: 0px 10px 25px rgba(94, 94, 94, 0.25);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .select2-container--default .select2-selection--single {
        width: 100%;
        max-width: 36.2rem;
        margin-bottom: 2.4rem;

        border: none;
        border-radius: 0;
        border-bottom: 1px solid $txt-silver;
        background: none;
        padding-bottom: .4rem;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 2.1rem;
        color: $txt-black;
        transition: all .3s;

        &:active,
        &:focus {
            border-bottom: 1px solid $orange-1;
        }
    }
    .form-discount__inp {
        margin-bottom: 2.4rem;
    }

    .form-discount__btn {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        min-width: 22.6rem;
        margin-top: 3.6rem;
        margin-bottom: 1.5rem;
        cursor: pointer;
    }

    .form-discount__check {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .form-checkbox {
            padding: 0;
            height: initial;
            width: initial;
            margin-bottom: 0;
            display: none;
            cursor: pointer;
        }

        .form-checkbox:checked + .check-label::after {
            content: '';
            display: block;
            position: absolute;
            top: 3.7px;
            left: -1.7rem;
            width: 2.5px;
            height: 5px;
            border: solid #F37321;
            border-width: 0 .5px .5px 0;
            transform: rotate(45deg);
        }

        .check-label {
            font-weight: 300;
            font-size: .9rem;
            line-height: 1.3rem;
            color: $txt-silver;
            cursor: pointer;
            position: relative;

            &::before {
                content:'';
                -webkit-appearance: none;
                background-color: transparent;
                width: .8rem;
                height: .8rem;
                border: .5px solid $txt-silver;
                border-radius: .2rem;
                display: inline-block;
                position: absolute;
                vertical-align: middle;
                cursor: pointer;
                left: -2rem;
                top: 50%;
                transform: translateY(-39%);
            }
        }
        
    }
}

.select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 0;
    padding-right: 2.8rem;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: $txt-silver;
    transition: all .3s;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__placeholder {
    color: $txt-black;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    transition: all .3s;
    border-color: unset;
    border-style: unset;
    border-width: unset;
    margin-top: unset;
    margin-left: unset;
    transform: translate(-50%, -50%);
    width: .6rem;
    height: 1.2rem;
    background-image: url('../img/components/form-select__close.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    transition: all .3s;
    transform: translate(-50%, -50%) rotate(90deg);
    background-image: url('../img/components/form-select__open.svg');
}

.select2-container--open .select2-dropdown--below {
    top: -2.4rem!important;
}
.select2-dropdown {
    border: unset;
    border-radius: unset;
    background: #F6F6F6;
}
.select2-results__option {
    padding: 1.2rem 1.2rem 1.2rem 3.1rem;
}
.select2-results__option[aria-selected] {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 1.9rem;
}
.select2-container--default .select2-results__option[aria-selected=true] {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 1.9rem;
    background-color: #F6F6F6;
    transition: all .3s;
}

.select2-results__option {
    position: relative;

    &::before {
        content: "";
        width: .8rem;
        height: .8rem;
        border: 1px solid $txt-silver;
        border-radius: .2rem;

        position: absolute;
        left: 3%;
        top: 52%;
        transform: translateY(-50%);
        z-index: 1;
    }

    &.select2-results__option--highlighted {

        &::after {
            content: url('../img/components/option-checked.png');
            position: absolute;
            left: 3.2%;
            top: 47.8%;
            transform: translateY(-50%);
            z-index: 2;
        }
    }
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 1.9rem;
    background-color: #F0F0F0;
    color: $orange-1;
    transition: all .3s;
}
// END //

// STYLES OF SLIDER DOTS //
.slick-dots {
    display: flex;
    justify-content: center;
    align-items: center;
}

.slick-dots li {
    width: 1rem;
    height: 1rem;
    background-color: $bg-silver2;
    border: 1px solid $bg-silver2;
    border-radius: 50%;
    position: relative;
}
.slick-dots li.slick-active {
    border: 1px solid $orange-1;
    background-color: transparent;
    width: 1.6rem;
    height: 1.6rem;

    &::after {
        content: "";
        width: .5rem;
        height: .5rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background-color: $orange-1;
    }
}
// END //

// POPUP STYLES //
.success-popup__wrap {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    overflow: auto;
    width: 100%;
    height: 100%;
    background-color: rgba(196, 196, 196, 0.9);

    .success-popup {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 55.7rem;
        min-height: 38.5rem;
        background-color: $txt-white;
        border-radius: .5rem;
        padding: 2rem;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .popup-element {
            position: absolute;
            
            &.popup-share {
                top: 11%;
                left: 5%;
                transform: rotate(90deg);
                width: 3.8rem;
                opacity: .5;
            }
            
            &.popup-plus {
                top: 72%;
                left: 10%;
            }

            &.popup-wawe {
                width: 7.7rem;
                top: 83%;
                left: 76%;
            }
        }

        .popup-close {
            position: absolute;
            top: 2rem;
            right: 2rem;
            width: 4rem;
            height: 4rem;
            background-image: url('../img/components/close.png');
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
        }

        .popup-ttl {
            font-weight: 600;
            font-size: 3.6rem;
            line-height: 4.4rem;
            text-align: center;
            text-transform: uppercase;
            color: $orange-1;
            margin-bottom: 2.7rem;
        }

        .popup-subttl {
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            color: $black-1;
            max-width: 37rem;
        }
    }
}
// END //