.cg {
  width: 100%;
  max-width: 117.8rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin: 0 auto;
}

.ttl {
  font-weight: 600;
  font-size: 3rem;
  line-height: 3.7rem;
  color: #F37321;
  margin: 0;
}

.ttl-2 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #000;
}

.txt {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #fff;
  text-decoration: none;
  margin: 0;
}

.btn {
  background: #EE9B66;
  border: none;
  border-radius: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.5rem;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #F6F6F6;
  transition: all .3s;
  text-transform: uppercase;
}

.btn:hover, .btn:active {
  box-shadow: 0px 5px 15px rgba(243, 115, 33, 0.36);
}

.inp {
  width: 100%;
  max-width: 36.2rem;
  border: none;
  border-bottom: 1px solid #B7B7B7;
  background: none;
  padding-bottom: .4rem;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #000;
  transition: all .3s;
}

.inp::placeholder {
  color: #B7B7B7;
  transition: all .3s;
}

.inp:active, .inp:focus {
  border-bottom: 1px solid #F37321;
}

.inp:active::placeholder, .inp:focus::placeholder {
  color: #000;
}

.body {
  position: relative;
}

.body .vertical-line {
  position: absolute;
  top: 0;
  height: 100%;
  width: .1rem;
  background-color: rgba(196, 196, 196, 0.3);
  z-index: 1;
}

.body .vertical-line.line-1 {
  left: 7.8%;
}

.body .vertical-line.line-2 {
  left: 19.2%;
}

.body .vertical-line.line-3 {
  left: 34.65%;
}

.body .vertical-line.line-4 {
  left: 82.34%;
}

.body .vertical-line.line-5 {
  left: 7.8%;
}

.body .vertical-line.line-6 {
  left: 19.2%;
}

.body .vertical-line.line-7 {
  left: 82.35%;
}

.header-menu {
  position: relative;
  z-index: 3;
  padding-top: 2.7rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-menu .camp-page__back {
  position: absolute;
  top: 100%;
  left: 2%;
  border-left: 1px solid #474747;
  border-bottom: 1px solid #474747;
  width: 2.2rem;
  height: 2.2rem;
  transform: rotate(45deg);
}

.header-menu__wrap {
  width: 12.7rem;
  height: 12.3rem;
  position: absolute;
  top: 5%;
  left: 36%;
}

.header-menu__wrap.camp-page__logo {
  left: 50%;
  transform: translateX(-50%);
}

.header-menu__logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  display: block;
}

.header-menu__socials {
  display: none;
  align-items: center;
  justify-content: flex-start;
}

.header-menu__socials .header-socials__link {
  width: 2.5rem;
  height: 2.5rem;
}

.header-menu__socials .header-socials__link:not(:last-of-type) {
  margin-right: 1.5rem;
}

.header-menu__socials .header-socials__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  display: block;
}

.header-menu__langs {
  margin-right: .6rem;
}

.header-menu__langs .lang {
  color: #B7B7B7;
  line-height: 1.7rem;
}

.header-menu__langs .lang-slach,
.header-menu__langs .lang-active {
  color: #000;
}

.main .first-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: -4.6rem;
  position: relative;
  z-index: 2;
}

.main .first-section__component {
  position: absolute;
  z-index: 2;
}

.main .first-section__component.component-elipse {
  left: 96%;
  top: 31.7%;
}

.main .first-section__component.component-plus {
  left: -6%;
  top: 18%;
}

.main .first-section__component.component-shape {
  left: 30%;
  top: 79.4%;
}

.main .first-section__component.component-wawe {
  left: 77.7%;
  top: 91.7%;
}

.main .first-section__description {
  width: 100%;
  max-width: 36.2rem;
  margin-right: 12.9rem;
}

.main .first-section__description .description-ttl {
  width: 100%;
  max-width: 35.9rem;
  margin-bottom: 2.2rem;
}

.main .first-section__description .description-txt {
  color: #737373;
  margin-bottom: 6rem;
}

.main .first-section__description .description-socials {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.main .first-section__description .description-socials__link {
  width: 3rem;
  height: 3rem;
}

.main .first-section__description .description-socials__link:not(:last-of-type) {
  margin-right: 3rem;
}

.main .first-section__description .description-socials__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  display: block;
}

.main .first-section__slider {
  width: 46rem;
}

.main .first-section__slider div {
  width: 46rem;
}

.main .first-section__slider div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  display: block;
}

.main .first-section__slider .slick-prev {
  top: unset;
  left: unset;
  right: -42.5%;
  bottom: 0;
  background: url("../img/components/btn-arrow.png") 50% 50% no-repeat;
  transform: rotate(180deg);
}

.main .first-section__slider .slick-next {
  top: unset;
  right: -42.5%;
  bottom: 4.21%;
  background: url("../img/components/btn-arrow.png") 50% 50% no-repeat;
}

.main .first-section__slider .slick-prev,
.main .first-section__slider .slick-next {
  width: 6.8rem;
  height: 6rem;
  background-color: #474747;
  transition: all .3s;
}

.main .first-section__slider .slick-prev:hover,
.main .first-section__slider .slick-next:hover {
  background-color: #F37321;
}

.camp-main {
  position: relative;
  z-index: 2;
  padding-top: 14.1rem;
  padding-bottom: 5.6rem;
}

.camp-main__component {
  position: absolute;
}

.camp-main__component.main-shape {
  width: 5.8rem;
  top: 2%;
  left: -10%;
}

.camp-main__component.main-rounds {
  top: 15%;
  left: 96%;
}

.camp-main__component.main-dots {
  z-index: -1;
  top: 21%;
  left: 46%;
}

.camp-main__component.main-wawe1 {
  width: 6rem;
  top: 53%;
  left: 86%;
}

.camp-main__component.main-plus {
  width: 4rem;
  top: 65%;
  left: 56.47%;
}

.camp-main__component.main-wawe2 {
  width: 5.6rem;
  top: 94%;
  left: 66%;
  z-index: 3;
  opacity: .5;
}

.camp-main__booking {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 13rem;
}

.camp-main__booking .booking-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 12.7rem;
}

.camp-main__booking .booking-info__ttl {
  max-width: 41.8rem;
  margin-bottom: 3rem;
}

.camp-main__booking .booking-info__ttl.camp2-ttl {
  max-width: 43.8rem;
}

.camp-main__booking .booking-info__wrap {
  max-width: 46rem;
  min-height: 33.4rem;
}

.camp-main__booking .booking-info__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  display: block;
}

.camp-main__booking .booking-block .camps-card {
  width: 46.1rem;
  max-width: unset;
  background-color: #fff;
}

.camp-main__booking .booking-block .camps-card.camp2-card {
  width: unset;
  max-width: 36.1rem;
}

.camp-main__booking .booking-block .camps-card:first-of-type {
  margin-right: 0;
}

.camp-main__booking .booking-block .camps-card:hover {
  background-color: #EE9B66;
  border: 1px solid #EE9B66;
  box-shadow: 0 1rem 2.5rem rgba(243, 115, 33, 0.5);
}

.camp-main__booking .booking-block .camps-card .camps-card__list {
  margin-bottom: 2.2rem;
}

.camp-main__booking .booking-block .camps-card .camps-card__list .list-item:first-of-type {
  min-height: unset;
}

.camp-main__booking .booking-block .camps-card .camps-card__list .list-item:not(:last-of-type) {
  margin-bottom: 2rem;
}

.camp-main__booking .booking-block .camps-card .price-block {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.camp-main__booking .booking-block .camps-card .price-block .camps-card__price {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.3rem;
  max-width: 17rem;
}

.camp-main__booking .booking-block .camps-card .camps-card__more:link,
.camp-main__booking .booking-block .camps-card .camps-card__more:visited {
  align-self: center;
}

.camps-card__description {
  font-weight: 300;
  font-size: 1.1rem;
  line-height: 1.2rem;
  color: #737373;
  margin-bottom: 4rem;
}

.camp-main__program {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.camp-main__program .program-left__subttl {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.3rem;
  color: #F37321;
  margin-top: 1.6rem;
}

.camp-main__program .program-left__description {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.camp-main__program .program-left__description .description-item {
  position: relative;
  color: #474747;
  margin-top: 3.1rem;
  margin-right: 7.6rem;
  max-width: 14rem;
}

.camp-main__program .program-left__description .description-item:first-of-type, .camp-main__program .program-left__description .description-item:nth-of-type(2), .camp-main__program .program-left__description .description-item:nth-of-type(3) {
  width: 50%;
  max-width: 27rem;
}

.camp-main__program .program-left__description .description-item::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: -.7rem;
  left: -1rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #F37321;
}

.camp-main__program .program-left__description .description-item__camp2 {
  position: relative;
  color: #474747;
  margin-top: 3.1rem;
  margin-right: 7.6rem;
  width: 100%;
  max-width: 28rem;
}

.camp-main__program .program-left__description .description-item__camp2::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: -.7rem;
  left: -1rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #F37321;
}

.camp-main__program .program-right {
  margin-top: 5.2rem;
}

.camp-main__program .program-right .camp-main__slider {
  width: 100%;
  max-width: 36.2rem;
  min-height: 27rem;
  border-radius: .5rem;
}

.camp-main__program .program-right .camp-main__slider .slide-wrap {
  width: 100%;
  max-width: 36.2rem;
  min-height: 27rem;
  border-radius: .5rem;
}

.camp-main__program .program-right .camp-main__slider .slide-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  display: block;
  border-radius: .5rem;
}

.camp-main__program .program-right .camp-page__slider.camp-main__slider .slick-prev,
.camp-main__program .program-right .camp-page__slider.camp-main__slider .slick-next {
  top: 90%;
}

.camp-main__program .program-right .camp-page__slider.camp-main__slider .slick-prev {
  right: 9.25%;
}

@media only screen and (max-width: 89.9375em) {
  .main .first-section__component.component-plus {
    left: 0%;
  }
  .main .first-section__component.component-elipse {
    left: 89%;
  }
  .camp-main__component.main-shape {
    left: 0%;
  }
  .camp-main__component.main-rounds {
    top: 7%;
    left: 89%;
  }
}

@media only screen and (max-width: 74.9375em) {
  .main .first-section__component.component-wawe {
    left: 41.5%;
  }
  .main .first-section__description {
    margin-right: 8rem;
  }
  .main .first-section__slider .slick-next,
  .main .first-section__slider .slick-prev {
    right: -14.8%;
  }
}

@media only screen and (max-width: 63.9375em) {
  .main .first-section__description {
    margin-right: 2rem;
  }
  .camp-main__component.main-wawe2 {
    top: 89%;
    left: 60%;
  }
}

@media only screen and (max-width: 74.9375em) {
  .body .vertical-line {
    display: none;
  }
  .header-menu {
    justify-content: flex-start;
    padding-top: 2rem;
  }
  .header-menu__wrap {
    width: 6rem;
    height: 6rem;
    position: relative;
    top: unset;
    left: unset;
    margin-right: 1.5rem;
  }
  .header-menu__socials {
    display: flex;
  }
  .header-menu__langs {
    margin-right: 0;
    margin-left: auto;
    display: flex;
    flex-direction: column;
  }
  .header-menu__langs .lang-slach {
    display: none;
  }
  .main .first-section__component.component-plus,
  .main .first-section__component.component-elipse,
  .main .first-section__component.component-wawe {
    display: none;
  }
  .main .first-section__component.component-shape {
    left: 60%;
    top: 108%;
  }
  .main .first-section {
    margin-top: 3.2rem;
    margin-bottom: 3rem;
  }
  .main .first-section__description {
    margin-right: 0;
    max-width: 100%;
  }
  .main .first-section__description .description-txt {
    margin-bottom: 0;
  }
  .main .first-section__description .description-socials {
    display: none;
  }
  .first-section__slider {
    display: none;
  }
  .camp-main__booking .booking-info {
    margin-right: 6rem;
  }
  .camp-main__program .program-left__description .description-item {
    margin-right: 2rem;
  }
}

@media only screen and (max-width: 61.9375em) {
  .camp-main__component.main-shape,
  .camp-main__component.main-wawe2,
  .camp-main__component.main-rounds,
  .camp-main__component.main-dots {
    display: none;
  }
  .camp-main__component.main-wawe1 {
    top: 59%;
    left: 58%;
  }
  .camp-main__component.main-plus {
    top: 70%;
    left: 74%;
  }
  .header-menu .camp-page__back {
    top: unset;
    left: 5%;
  }
  .camp-main {
    padding-top: 3rem;
    padding-bottom: 6rem;
  }
  .camp-main__booking {
    margin-bottom: 6rem;
    flex-direction: column;
  }
  .camp-main__booking .booking-info {
    margin-right: 0;
    margin-bottom: 2rem;
  }
  .camp-main__program {
    flex-direction: column;
  }
  .camp-main__program .program-right {
    margin-top: 3.3rem;
    align-self: center;
  }
  .camp-main__program .program-left__description .description-item:first-of-type,
  .camp-main__program .program-left__description .description-item:nth-of-type(2),
  .camp-main__program .program-left__description .description-item:nth-of-type(3),
  .camp-main__program .program-left__description .description-item {
    width: unset;
    max-width: 33%;
  }
}

@media only screen and (max-width: 47.9375em) {
  .camp-main__component.main-wawe1 {
    top: 55%;
    left: 53%;
  }
  .camp-main__component.main-plus {
    top: 67%;
    left: 76%;
  }
  .camp-main__program .program-left__description .description-item:first-of-type,
  .camp-main__program .program-left__description .description-item:nth-of-type(2),
  .camp-main__program .program-left__description .description-item:nth-of-type(3),
  .camp-main__program .program-left__description .description-item {
    max-width: calc(50% - 2rem);
  }
  .camp-main__program .program-left__description .description-item:nth-of-type(2n) {
    margin-right: 0;
  }
}

@media only screen and (max-width: 35.9375em) {
  .main .first-section__description .description-ttl {
    font-size: 2.4rem;
    line-height: 2.9rem;
  }
  .camp-main__booking .booking-info__ttl {
    font-size: 2.5rem;
    line-height: 2.9rem;
  }
  .camp-main__program .program-left__ttl {
    font-size: 1.8rem;
    line-height: 2.3rem;
  }
  .camp-main__program .program-left__subttl {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  .camp-main__component.main-wawe1 {
    top: 55.7%;
  }
  .camp-main__booking {
    margin-bottom: 1.6rem;
  }
  .camp-main__booking .booking-info__wrap {
    min-height: unset;
  }
  .camp-main__booking .booking-block .camps-card {
    width: 100%;
    max-width: 46.1rem;
  }
}

@media only screen and (max-width: 26.5em) {
  .camp-main__component.main-wawe1 {
    top: 54.5%;
  }
  .camp-main__booking .booking-info__ttl {
    max-width: 28rem;
  }
  .camp-main__booking .booking-block .camps-card .price-block {
    flex-wrap: wrap;
  }
  .camp-main__booking .booking-block .camps-card .price-block .camps-card__price:first-of-type {
    margin-right: 2rem;
  }
  .camp-main__program .program-left__description {
    flex-direction: column;
  }
  .camp-main__program .program-left__description .description-item:first-of-type,
  .camp-main__program .program-left__description .description-item:nth-of-type(2),
  .camp-main__program .program-left__description .description-item:nth-of-type(3),
  .camp-main__program .program-left__description .description-item {
    max-width: 100%;
    margin-right: 0;
  }
  .camp-main__program .program-right .camp-main__slider {
    max-width: 28rem;
    min-height: unset;
  }
  .camp-main__program .program-right .camp-main__slider .slide-wrap {
    min-height: unset;
  }
}

.camps-wrap {
  position: relative;
  padding-top: 9rem;
  padding-bottom: 7.7rem;
  background-color: rgba(229, 229, 229, 0.35);
}

.camps-wrap .camps-component {
  position: absolute;
  z-index: 2;
}

.camps-wrap .camps-component.camps-plus {
  left: 85%;
  top: 20%;
}

.camps-wrap .camps-component.camps-shape {
  left: 10.1%;
  top: 30%;
}

.camps-wrap .camps-component.camps-wawe {
  left: 32.5%;
  top: 70%;
}

.camps {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}

.camps-ttl {
  width: 100%;
  max-width: 33rem;
  margin-right: 3rem;
  margin-top: 6.2rem;
}

.camps-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #F6F6F6;
  border: 1px solid #B7B7B7;
  border-radius: 1rem;
  padding: 1.7rem 3rem 3rem 3rem;
  width: 100%;
  max-width: 36.1rem;
  min-height: 42.3rem;
  transition: all .6s;
}

.camps-card:hover {
  background-color: #EE9B66;
  border: 1px solid #EE9B66;
  box-shadow: 0 1rem 2.5rem rgba(243, 115, 33, 0.5);
}

.camps-card:hover > .camps-card__name,
.camps-card:hover > .camps-card__price,
.camps-card:hover > .price-block > .camps-card__price,
.camps-card:hover > .camps-card__description {
  color: #fff;
}

.camps-card:hover > .camps-card__list .list-item {
  color: #fff;
}

.camps-card:hover > .camps-card__list .list-item:first-of-type::before, .camps-card:hover > .camps-card__list .list-item:nth-of-type(2)::before, .camps-card:hover > .camps-card__list .list-item:nth-of-type(3)::before, .camps-card:hover > .camps-card__list .list-item:nth-of-type(4)::before {
  filter: invert(1);
}

.camps-card:hover > .camps-card__more {
  border: 2px solid #fff;
  color: #fff;
}

.camps-card__name {
  width: 100%;
  max-width: 26rem;
  display: block;
  margin-bottom: 1.9rem;
  font-weight: 600;
  color: #000;
}

.camps-card__list {
  margin-bottom: 2rem;
  list-style-type: none;
}

.camps-card__list .list-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #474747;
  position: relative;
  padding-left: 4.7rem;
}

.camps-card__list .list-item:first-of-type {
  min-height: 9rem;
}

.camps-card__list .list-item:first-of-type::before {
  content: url("../img/components/icon-calendar.png");
  position: absolute;
  left: 0;
}

.camps-card__list .list-item:nth-of-type(2) {
  max-width: 28rem;
}

.camps-card__list .list-item:nth-of-type(2)::before {
  content: url("../img/components/icon-location.png");
  position: absolute;
  left: 0;
}

.camps-card__list .list-item:nth-of-type(3) {
  max-width: 20rem;
}

.camps-card__list .list-item:nth-of-type(3)::before {
  content: url("../img/components/icon-children.png");
  position: absolute;
  left: 0;
}

.camps-card__list .list-item:nth-of-type(4) {
  max-width: 22rem;
}

.camps-card__list .list-item:nth-of-type(4)::before {
  content: url("../img/components/icon-bus.png");
  position: absolute;
  left: 0;
}

.camps-card__list .list-item:not(:last-of-type) {
  margin-bottom: .9rem;
}

.camps-card__price {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #000;
  margin-bottom: 2rem;
}

.camps-card__more:link, .camps-card__more:visited {
  text-decoration: none;
  width: 100%;
  max-width: 22rem;
  height: 4.5rem;
  background: #EE9B66;
  border-radius: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #F6F6F6;
}

.review {
  position: relative;
  padding-top: 5.3rem;
  padding-bottom: 5.9rem;
}

.review .review-component {
  position: absolute;
}

.review .review-component.review-dots {
  top: 16%;
  left: -23.2%;
}

.review .review-component.review-wawe {
  top: 9%;
  left: 73%;
}

.review .review-component.review-plus {
  top: 88%;
  left: -11%;
}

.review .review-component.review-round {
  top: 62%;
  left: 39%;
}

.review .review-component.review-wawe2 {
  top: 86%;
  left: 87%;
}

.review-ttl {
  margin-bottom: 4.9rem;
  width: 100%;
  max-width: 51.8rem;
}

.review .video_wrapper {
  position: relative;
  width: 100%;
  max-width: 95rem;
  height: 40rem;
  margin: 0 auto 8.3rem auto;
  z-index: 2;
}

.review .videoIframe {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.review .videoPoster {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(71, 71, 71, 0.5), rgba(71, 71, 71, 0.5)), url("../img/bg/review-video__img.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  border: none;
  text-indent: -999em;
  overflow: hidden;
  opacity: 1;
  -webkit-transition: opacity 800ms, height 0s;
  -moz-transition: opacity 800ms, height 0s;
  transition: opacity 800ms, height 0s;
  -webkit-transition-delay: 0s, 0s;
  -moz-transition-delay: 0s, 0s;
  transition-delay: 0s, 0s;
}

.review .videoPoster:hover {
  cursor: pointer;
}

.review .videoPoster:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  margin: -40px 0 0 -40px;
  border: 5px solid #fff;
  background-color: #f00;
  border-radius: 100%;
  -webkit-transition: border-color 300ms;
  -moz-transition: border-color 300ms;
  transition: border-color 300ms;
}

.review .videoPoster:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50.6%;
  transform: translate(-50%, -50%);
  border-left: 30px solid #fff;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  -webkit-transition: border-color 300ms;
  -moz-transition: border-color 300ms;
  transition: border-color 300ms;
}

.review .videoPoster:hover:before, .review .videoPoster:focus:before {
  border-color: #EE9B66;
  background-color: #fff;
}

.review .videoPoster:hover:after, .review .videoPoster:focus:after {
  border-left-color: #EE9B66;
}

.review .videoWrapperActive .videoPoster {
  opacity: 0;
  height: 0;
  -webkit-transition-delay: 0s, 800ms;
  -moz-transition-delay: 0s, 800ms;
  transition-delay: 0s, 800ms;
}

.review-slider__ttl {
  width: 100%;
  max-width: 37rem;
  margin-bottom: 2rem;
}

.review-slider {
  width: 100%;
  max-width: 72.4rem;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.review-slider .slick-prev {
  background: url("../img/components/review-slider__arrow.png") 50% 50% no-repeat;
  transform: translate(0, -50%) rotate(180deg);
  left: -5.7rem;
}

.review-slider .slick-next {
  background: url("../img/components/review-slider__arrow.png") 50% 50% no-repeat;
  right: -5.7rem;
  transform: translate(0, -50%);
}

.statistic-wrap {
  position: relative;
  padding-top: 6.4rem;
  padding-bottom: 5.5rem;
  background-color: #F6F6F6;
}

.statistic-wrap .statistic-component {
  position: absolute;
}

.statistic-wrap .statistic-component.statistic-dots {
  left: 64.4%;
  top: 63%;
}

.statistic {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.statistic-info {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 38.6rem;
  margin-right: 7.7rem;
}

.statistic-info__ttl {
  color: #F37321;
  width: 100%;
  margin-bottom: 3rem;
}

.statistic-info__block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 50%;
  max-width: 15rem;
}

.statistic-info__block:nth-of-type(1), .statistic-info__block:nth-of-type(2) {
  margin-bottom: 5rem;
}

.statistic-info__block:nth-of-type(2n+1) {
  margin-right: 2rem;
}

.statistic-info__block:last-of-type .block-ttl {
  max-width: 14.9rem;
}

.statistic-info__block .block-img__wrap {
  width: 3rem;
  height: 3rem;
  margin-bottom: .8rem;
}

.statistic-info__block .block-img__wrap .block-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  display: block;
}

.statistic-info__block .block-ttl {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1;
  color: #474747;
  margin-bottom: .5rem;
  width: 100%;
  max-width: 11.9rem;
  min-height: 2.8rem;
}

.statistic-info__block .block-count {
  font-weight: bold;
  font-size: 3.6rem;
  line-height: 4.5rem;
  color: #EE9B66;
  transition: all .3s;
  opacity: 0;
}

.statistic-years {
  position: relative;
}

.statistic-years__wrap {
  width: 100%;
  max-width: 61.8rem;
  min-height: 39.2rem;
}

.statistic-years__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  display: block;
}

.statistic-years__btn {
  position: absolute;
  top: 20%;
  right: -12%;
  min-width: 16.6rem;
}

.form-discount {
  position: relative;
  padding-top: 5.2rem;
  padding-bottom: 8rem;
}

.form-discount .discount-rectangular {
  position: absolute;
  z-index: 2;
  top: 21.1%;
  left: 22%;
  width: 13.7rem;
  height: 52.7rem;
  background: #F6F6F6;
  border-radius: .5rem;
}

.form-discount .discount-component {
  position: absolute;
}

.form-discount .discount-component.discount-wawe2 {
  top: 24%;
  left: 80%;
}

.form-discount .discount-component.discount-shape {
  top: 44%;
  left: 84%;
}

.form-discount .discount-component.discount-rounds {
  left: 27.5%;
  top: 7%;
}

.form-discount .discount-component.discount-plus {
  top: 30%;
  left: 7%;
}

.form-discount .discount-component.discount-wawe {
  z-index: 4;
  top: 55.2%;
  left: 23%;
}

.form-discount__ttl {
  text-align: center;
  width: 100%;
  max-width: 43rem;
  margin: 0 auto 1.6rem auto;
}

.form-discount__subttl {
  text-align: center;
  color: #F37321;
  line-height: 3.6rem;
  width: 100%;
  max-width: 78rem;
  margin: 0 auto 3.2rem auto;
}

.form-discount__gallery .gallery-ttl {
  color: #F37321;
  width: 100%;
  margin-bottom: 3rem;
}

.form-discount__gallery .discount-gallery {
  position: relative;
  z-index: 2;
}

.form-discount__gallery .discount-gallery .discount-gallery__wrap {
  position: relative;
  border-radius: .5rem;
}

.form-discount__gallery .discount-gallery .discount-gallery__wrap .discount-gallery__slide .discount-gallery__img {
  border-radius: .5rem;
}

.form-discount__gallery .discount-gallery .discount-gallery__wrap .discount-gallery__slide .img-overlay {
  width: 100%;
  max-width: 26.4rem;
  height: 100%;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  transition: all .3s;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: .5rem;
}

.form-discount__gallery .discount-gallery .discount-gallery__wrap .discount-gallery__slide .img-overlay::after {
  content: url("../img/components/gallery-search.png");
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all .3s;
  opacity: 0;
  cursor: pointer;
}

.form-discount__gallery .discount-gallery .discount-gallery__wrap:hover .img-overlay,
.form-discount__gallery .discount-gallery .discount-gallery__wrap:hover .img-overlay::after {
  opacity: 1;
}

.form-discount__gallery .discount-gallery .slick-prev {
  background: url("../img/components/review-slider__arrow.png") 50% 50% no-repeat;
  background-size: 1.2rem;
  background-color: #fff;
  left: 1.2rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  z-index: 4;
  transform: translate(0, -50%) rotate(180deg);
}

.form-discount__gallery .discount-gallery .slick-next {
  background: url("../img/components/review-slider__arrow.png") 50% 50% no-repeat;
  background-size: 1.2rem;
  background-color: #fff;
  right: 3.2rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  z-index: 4;
  transform: translate(0, -50%);
}

.form-booking__wrap {
  position: relative;
  padding-top: 6rem;
  padding-bottom: 7.5rem;
  background-color: #F6F6F6;
}

.form-booking__wrap .booking-component {
  position: absolute;
}

.form-booking__wrap .booking-component.booking-dots {
  top: -4.8%;
  left: 14.9%;
}

.form-booking__wrap .booking-component.booking-plus {
  top: 9%;
  left: 67%;
}

.form-booking__wrap .booking-component.booking-shape {
  top: 45%;
  left: 12%;
}

.form-booking__wrap .booking-component.booking-rounds {
  top: 59%;
  left: 74%;
}

.form-booking__wrap .booking-component.booking-wawe {
  top: 97.2%;
  left: 38.7%;
  z-index: 4;
}

.form-booking .form-booking__form {
  margin: 0 auto;
}

.camp-info__wrap {
  position: relative;
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: #F6F6F6;
}

.camp-info__wrap .camp-info__component {
  position: absolute;
}

.camp-info__wrap .camp-info__component.info-rounds {
  top: 38%;
  left: 14%;
  width: 5.4rem;
}

.camp-info__wrap .camp-info__component.info-dots {
  top: 68%;
  left: 34.2%;
  width: 32.2rem;
  height: 12.1rem;
}

.camp-info__wrap .camp-info__component.info-wawe {
  top: 69%;
  left: 69.5%;
  width: 7.7rem;
}

.camp-info {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.camp-info__slider {
  width: 100%;
  max-width: 49rem;
  min-height: 34rem;
  margin-right: 1.6rem;
  border-radius: .5rem;
}

.camp-info__slider .slide-wrap {
  width: 100%;
  max-width: 49rem;
  min-height: 34rem;
  border-radius: .5rem;
}

.camp-info__slider .slide-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  display: block;
  border-radius: .5rem;
}

.camp-info__description {
  max-width: 59rem;
}

.camp-info__description .description-txt {
  margin-top: 4.5rem;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #474747;
}

.camp-info__description .description-txt .txt-bold {
  color: #F37321;
}

.camp-page__slider .slick-prev,
.camp-page__slider .slick-next {
  background-color: #474747;
  width: 2.25rem;
  height: 2rem;
  z-index: 3;
  top: 93%;
}

.camp-page__slider .slick-prev {
  border-top-left-radius: 10rem;
  border-bottom-left-radius: 10rem;
  left: unset;
  right: 7.4%;
}

.camp-page__slider .slick-prev:after {
  content: "";
  background-image: url("../img/components/btn-arrow.png");
  background-size: cover;
  width: .3rem;
  height: .7rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(180deg);
}

.camp-page__slider .slick-next {
  border-top-right-radius: 10rem;
  border-bottom-right-radius: 10rem;
  right: 3%;
}

.camp-page__slider .slick-next:after {
  content: "";
  background-image: url("../img/components/btn-arrow.png");
  background-size: cover;
  width: .3rem;
  height: .7rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.camp-place {
  position: relative;
  z-index: 2;
  padding-top: 9.5rem;
  padding-bottom: 9rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.camp-place .camp-place__component {
  position: absolute;
}

.camp-place .camp-place__component.place-shape {
  width: 5.8rem;
  top: 73%;
  left: 35%;
}

.camp-place .camp-place__component.place-wawe {
  top: 37%;
  left: 110%;
}

.camp-place__description {
  max-width: 55.7rem;
  margin-right: 1.6rem;
}

.camp-place__description .description-ttl {
  color: #F37321;
  margin-bottom: 1.6rem;
}

.camp-place__description .description-txt {
  color: #000;
  font-weight: normal;
  line-height: 2.1rem;
  margin-bottom: 6rem;
}

.camp-place__description .description-btn {
  max-width: 26.4rem;
}

.camp-place__map {
  width: 55.8rem;
  height: 34rem;
  border-radius: .5rem;
  box-shadow: 0px 10px 35px rgba(118, 114, 114, 0.5);
}

.camp-place__map iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  display: block;
  border-radius: .5rem;
}

.camp-food__wrap {
  position: relative;
  padding-top: 5.8rem;
  padding-bottom: 5.5rem;
  background-color: #F6F6F6;
}

.camp-food__wrap .camp-food__component {
  position: absolute;
}

.camp-food__wrap .camp-food__component.food-shape {
  width: 3.6rem;
  top: 16%;
  left: 72%;
}

.camp-food__wrap .camp-food__component.food-plus {
  top: 42%;
  left: 5%;
}

.camp-food__wrap .camp-food__component.food-dots {
  width: 32.3rem;
  height: 12.1rem;
  top: 86%;
  left: 70%;
}

.camp-food {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
}

.camp-food__imgs {
  margin-right: 1.6rem;
  min-width: 42rem;
}

.camp-food__imgs.camp2-food {
  max-width: 55.8rem;
  min-height: 38.1rem;
  border-radius: .5rem;
}

.camp-food__imgs.camp2-food img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  display: block;
  border-radius: .5rem;
}

.camp-food__imgs .imgs-top__block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 3rem;
}

.camp-food__imgs .imgs-top__block .top-img__wrap {
  width: 100%;
  max-width: 26.4rem;
  height: 15.1rem;
  border-radius: .5rem;
}

.camp-food__imgs .imgs-top__block .top-img__wrap:first-of-type {
  margin-right: 1.6rem;
}

.camp-food__imgs .imgs-top__block .top-img__wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  display: block;
  border-radius: .5rem;
}

.camp-food__imgs .imgs-bottom__block {
  width: 100%;
  max-width: 55.8rem;
  height: 23.6rem;
  border-radius: .5rem;
}

.camp-food__imgs .imgs-bottom__block img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  display: block;
  border-radius: .5rem;
}

.camp-food__description {
  width: 100%;
  max-width: 55.8rem;
}

.camp-food__description .description-ttl {
  color: #F37321;
  margin-bottom: 1.6rem;
}

.camp-food__description .description-txt {
  color: #000;
  font-weight: normal;
  line-height: 2.1rem;
  margin-bottom: 6rem;
}

.camp-food__description .description-btn {
  max-width: 26.4rem;
}

.camp-sliders {
  position: relative;
  padding-top: 7.8rem;
  padding-bottom: 6.4rem;
}

.camp-sliders .camp-sliders__component {
  position: absolute;
}

.camp-sliders .camp-sliders__component.sliders-rounds {
  top: 6%;
  left: 38%;
}

.camp-sliders .camp-sliders__component.sliders-wawe {
  top: 34%;
  left: 86%;
}

.camp-sliders .camp-sliders__component.sliders-plus {
  top: 38%;
  left: -11%;
}

.camp-sliders .camp-review__slider {
  margin-bottom: 9.2rem;
}

.camp-booking__wrap {
  position: relative;
  padding-top: 5.3rem;
  padding-bottom: 8rem;
  background-color: #F6F6F6;
}

.camp-booking__wrap .camp-booking__component {
  position: absolute;
}

.camp-booking__wrap .camp-booking__component.rounds-1 {
  top: 10%;
  left: 23%;
  width: 3.6rem;
}

.camp-booking__wrap .camp-booking__component.plus {
  top: 38%;
  left: 11%;
}

.camp-booking__wrap .camp-booking__component.wawe-1 {
  top: 84%;
  left: 32.5%;
  z-index: 4;
}

.camp-booking__wrap .camp-booking__component.wawe-2 {
  top: 37%;
  left: 67%;
}

.camp-booking__wrap .camp-booking__component.rounds-2 {
  top: 74%;
  left: 75%;
}

.camp-booking__wrap .camp-booking__component.shape {
  display: none;
}

.camp-booking .camp-booking__form {
  margin: 0 auto;
}

.camp-booking .camp-booking__ttl {
  max-width: 74rem;
}

.footer {
  background-color: #474747;
}

.footer-menu {
  padding-top: 5.1rem;
  padding-bottom: 5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.footer-menu__wrap {
  width: 9.1rem;
  height: 8.9rem;
  margin-left: 9.9rem;
  margin-right: 10.4rem;
  text-decoration: none;
}

.footer-menu__logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  display: block;
}

.footer-menu__social {
  margin-right: 11.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.footer-menu__social .social-description {
  margin-bottom: 2.6rem;
  width: 100%;
  max-width: 18rem;
}

.footer-menu__social .social-block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.footer-menu__social .social-block__link {
  width: 3rem;
  height: 3rem;
  text-decoration: none;
}

.footer-menu__social .social-block__link:not(:last-of-type) {
  margin-right: 3rem;
}

.footer-menu__social .social-block__link .social-link__icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  display: block;
}

.footer-menu__contacts {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.footer-menu__contacts .contact-phone {
  margin-bottom: .8rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.footer-menu__contacts .contact-phone__link:link, .footer-menu__contacts .contact-phone__link:visited {
  color: #fff;
  font-weight: 300;
  position: relative;
  text-decoration: none;
  margin-top: 1.7rem;
  padding-left: 2rem;
}

.footer-menu__contacts .contact-phone__link:link::after, .footer-menu__contacts .contact-phone__link:visited::after {
  content: "";
  width: 1.4rem;
  height: 1.4rem;
  background-image: url("../img/components/phone-icon.png");
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.footer-menu__contacts .contact-mail {
  font-weight: 300;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.footer-menu__contacts .contact-mail__link:link, .footer-menu__contacts .contact-mail__link:visited {
  text-decoration: none;
  color: #F37321;
}

.form-discount__form {
  position: relative;
  z-index: 3;
  width: 100%;
  max-width: 55.7rem;
  min-height: 55.5rem;
  padding: 4.3rem 9.6rem 5.2rem 9.6rem;
  margin: 0 auto 9.2rem auto;
  background: #fff;
  border: 1px solid #F37321;
  border-radius: 1rem;
  box-shadow: 0px 10px 25px rgba(94, 94, 94, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.form-discount__form .select2-container--default .select2-selection--single {
  width: 100%;
  max-width: 36.2rem;
  margin-bottom: 2.4rem;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #B7B7B7;
  background: none;
  padding-bottom: .4rem;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #000;
  transition: all .3s;
}

.form-discount__form .select2-container--default .select2-selection--single:active, .form-discount__form .select2-container--default .select2-selection--single:focus {
  border-bottom: 1px solid #F37321;
}

.form-discount__form .form-discount__inp {
  margin-bottom: 2.4rem;
}

.form-discount__form .form-discount__btn {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  min-width: 22.6rem;
  margin-top: 3.6rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
}

.form-discount__form .form-discount__check {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.form-discount__form .form-discount__check .form-checkbox {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-discount__form .form-discount__check .form-checkbox:checked + .check-label::after {
  content: '';
  display: block;
  position: absolute;
  top: 3.7px;
  left: -1.7rem;
  width: 2.5px;
  height: 5px;
  border: solid #F37321;
  border-width: 0 .5px .5px 0;
  transform: rotate(45deg);
}

.form-discount__form .form-discount__check .check-label {
  font-weight: 300;
  font-size: .9rem;
  line-height: 1.3rem;
  color: #B7B7B7;
  cursor: pointer;
  position: relative;
}

.form-discount__form .form-discount__check .check-label::before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  width: .8rem;
  height: .8rem;
  border: 0.5px solid #B7B7B7;
  border-radius: .2rem;
  display: inline-block;
  position: absolute;
  vertical-align: middle;
  cursor: pointer;
  left: -2rem;
  top: 50%;
  transform: translateY(-39%);
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0;
  padding-right: 2.8rem;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #B7B7B7;
  transition: all .3s;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__placeholder {
  color: #000;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  transition: all .3s;
  border-color: unset;
  border-style: unset;
  border-width: unset;
  margin-top: unset;
  margin-left: unset;
  transform: translate(-50%, -50%);
  width: .6rem;
  height: 1.2rem;
  background-image: url("../img/components/form-select__close.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  transition: all .3s;
  transform: translate(-50%, -50%) rotate(90deg);
  background-image: url("../img/components/form-select__open.svg");
}

.select2-container--open .select2-dropdown--below {
  top: -2.4rem !important;
}

.select2-dropdown {
  border: unset;
  border-radius: unset;
  background: #F6F6F6;
}

.select2-results__option {
  padding: 1.2rem 1.2rem 1.2rem 3.1rem;
}

.select2-results__option[aria-selected] {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 1.9rem;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 1.9rem;
  background-color: #F6F6F6;
  transition: all .3s;
}

.select2-results__option {
  position: relative;
}

.select2-results__option::before {
  content: "";
  width: .8rem;
  height: .8rem;
  border: 1px solid #B7B7B7;
  border-radius: .2rem;
  position: absolute;
  left: 3%;
  top: 52%;
  transform: translateY(-50%);
  z-index: 1;
}

.select2-results__option.select2-results__option--highlighted::after {
  content: url("../img/components/option-checked.png");
  position: absolute;
  left: 3.2%;
  top: 47.8%;
  transform: translateY(-50%);
  z-index: 2;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 1.9rem;
  background-color: #F0F0F0;
  color: #F37321;
  transition: all .3s;
}

.slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-dots li {
  width: 1rem;
  height: 1rem;
  background-color: #E5E5E5;
  border: 1px solid #E5E5E5;
  border-radius: 50%;
  position: relative;
}

.slick-dots li.slick-active {
  border: 1px solid #F37321;
  background-color: transparent;
  width: 1.6rem;
  height: 1.6rem;
}

.slick-dots li.slick-active::after {
  content: "";
  width: .5rem;
  height: .5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #F37321;
}

.success-popup__wrap {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: rgba(196, 196, 196, 0.9);
}

.success-popup__wrap .success-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 55.7rem;
  min-height: 38.5rem;
  background-color: #fff;
  border-radius: .5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.success-popup__wrap .success-popup .popup-element {
  position: absolute;
}

.success-popup__wrap .success-popup .popup-element.popup-share {
  top: 11%;
  left: 5%;
  transform: rotate(90deg);
  width: 3.8rem;
  opacity: .5;
}

.success-popup__wrap .success-popup .popup-element.popup-plus {
  top: 72%;
  left: 10%;
}

.success-popup__wrap .success-popup .popup-element.popup-wawe {
  width: 7.7rem;
  top: 83%;
  left: 76%;
}

.success-popup__wrap .success-popup .popup-close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 4rem;
  height: 4rem;
  background-image: url("../img/components/close.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.success-popup__wrap .success-popup .popup-ttl {
  font-weight: 600;
  font-size: 3.6rem;
  line-height: 4.4rem;
  text-align: center;
  text-transform: uppercase;
  color: #F37321;
  margin-bottom: 2.7rem;
}

.success-popup__wrap .success-popup .popup-subttl {
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #474747;
  max-width: 37rem;
}
